<template>
  <div class="msp-workout">
  <div class="msp-esporta-garmin">
    <v-progress-linear
    indeterminate
    v-show="isLoading"
    color="var(--col-msp)"
    ></v-progress-linear>
    
    <v-card outlined class="my-2" v-if="!annullato.gatmin && !esportato.garmin">
        <v-card-title>Invia a Garmin Connect</v-card-title>
        <v-card-text>
            <div class="my-2" v-if="autorizzato.garmin" ><v-btn block  @click="inviaGarminSingolo(allenamento.calendario_id)" color="primary">Solo questo allenamento</v-btn></div>
            <div class="my-2" v-if="autorizzato.garmin" ><v-btn block  @click="inviaGarminSettimana(allenamento.data)" color="primary">7 giorni dal {{formatDate(allenamento.data)}}</v-btn></div>
            <div v-else class="my-2"><v-btn block  @click="autorizzaGarmin()" color="primary">Connetti Garmin</v-btn></div>
        </v-card-text>
    </v-card>
    <v-alert
    v-model="annullato.garmin"
    :type="message.type"
    >
    {{message.text}}
    </v-alert>
    <v-alert
    v-model="esportato.garmin"
    type="success"
    >
    Inviato a Garmin Connect
    </v-alert>
    
    <v-card outlined class="my-2" v-if="allenamento.sport_id===2 && !esportato.zwo">
        <v-card-title>File ZWO</v-card-title>
        <v-card-text ><v-btn block @click="scaricaZwo()" color="primary">Esporta questo allenamento</v-btn></v-card-text>
    </v-card>
    <template v-else >
    <v-alert
    v-model="esportato.zwo"
    type="success"
    >
    File ZWO creato
    </v-alert>

<a v-if="url.zwo" :href="url.zwo" segmento="_blank">scarica file ZWO</a>
    </template>

  <v-alert
  v-model="showMessage"
  :type="message.type"
  dismissible
  >
  {{message.text}}
</v-alert>

</div>

</div>




    
</template>

<script>
import _storage from "@mixins/storage.js"
import _api from "@mixins/api.js";
import env from "@mixins/environment.js"
import funzioni from "@src/js/vue/mixins/allenamentoCalcoli.js"
import {inviaGarmin} from '@lib/garminMessages.js';

const component = {
  mixins: [
    env,
    funzioni,
  ],
  props: [
    'allenamento',
  ],

  data() {
      return {
          showMessage: false,
          popupWorkout: null,
          autorizzato: {
              garmin: false,
          },
          annullato: {
              garmin: false,
          },
          esportato: {
              garmin: false,
              zwo: false,
          },
          url: {
              zwo: null,
          },
          oauth_token: {
              garmin: false,
          },
          message: {
              type: 'success',
              text: '',
          },
          authWindow: null,
          isLoading: false,
          isCompleted: false,
      }
  },
  computed: {
  },
  mounted() {

    this.verificaAutorizzazioneGarmin();

    window.addEventListener("message", (event) => {
      if (!this.popupWorkout) {
        return;
      }
      if (event.origin !== window.location.origin)
      {
        // return;

      }

      if (event.data === 'MSP_AUTH_GARMIN') {
        this.popupWorkout.close();
        this.autorizzato.garmin = true;
      }
      if (event.data === 'MSP_AUTH_ERROR') {
        this.popupWorkout.close();
        this.autorizzato.garmin = false;
      }
      if (event.data === 'MSP_SENT_GARMIN') {
        this.popupWorkout.close();
        this.esportato.garmin = true;
      }

      // ...
    }, false);


  },
  methods: {
      setMessage: function (text, type) {
          this.showMessage = true;
              this.message = {
                "text": text,
                "type":type
              };
        },
        setFile: function (type, filename) {
            const endpoint = "https://app.coachpeaking.com/download.php?path=";
            this.url[type] = endpoint+filename;
        },
        scaricaZwo: async function () {
            const filename = await this.esportaZwo();
            if (!filename) {
                this.erroreZwo();
            } else {
                this.setFile("zwo",filename);
                this.downloadZwo(filename);
            }
        },
        erroreZwo: function () {
              const message = {
                "text": "Non posso scaricare il file",
                "type": "error"
              };
              this.setMessage(message.text, message.type);
        },
        downloadZwo: function (filename) {
              this.esportato.zwo = true;
                const url = this.api_base_url
                    +"/download.php?path="+filename;
              window.open(url);
            },
      esportaZwo: function () {
        return new Promise((resolve)=>{
        const url = this.api_base_url
            +"/api/v2/workout.php?zwo"
            +"&calendario_id="+this.allenamento.calendario_id;
        _api(url, "GET")
        .then((result)=>{
            this.isLoading = false;
            if (result.success) {
              resolve(result.data);
            } else {
              resolve(false);
            }
        })
        .catch(()=>{
            this.isLoading = false;
            resolve(false);
          });
      });

          },
      esportaErg: function () {},

    checkTokenGarminExport: async function () {
      this.isLoading = true;

      return new Promise((resolve, reject)=>{

        const url = this.api_base_url+"/api/v2/workout.php?token";

        _api(url, "GET")
        .then((result)=>{
            this.isLoading = false;
            if (result.success) {
              resolve(result.data);
            } else {
              resolve(false);
            }
        })
        .catch((error)=>{
            this.isLoading = false;
            reject(error);
          })
      });
    },

    inviaGarminSettimana: async function (giorno) {
        let data = {
            'da': giorno
        }
        return this.inviaGarmin('settimana', data);
    },

    inviaGarminSingolo: async function () {
        let data = {
            calendario_id: this.allenamento.calendario_id
        }
        return this.inviaGarmin('singolo', data);
    },

    inviaGarmin: async function (type = "singolo", data) {
        this.isLoading = true;
        const result = await inviaGarmin(type,data);
        this.isLoading = false;
        if (!result.message.type==='error') {
            this.esportato.garmin = true;
            this.isCompleted = true;
        } else {
            this.esportato.garmin = false;
            this.annullato.garmin = true;
        }
        this.message = result.message;
        return result.data;
    },

    verificaAutorizzazioneGarmin: async function () {
      this.isLoading = true;
      let token = await this.checkTokenGarminExport();
      this.oauth_token.garmin = token;
      if (token) {
        this.isLoading = false;
        this.autorizzato.garmin = true;
      } else {
        this.isLoading = false;
        this.autorizzato.garmin = false;
      }
    },

    autorizzaGarmin: function () {
        const auth_token = _storage.getAuthToken();
      const url = this.api_base_url+"/garmin/auth_workout.php?origin="+window.location.origin+"&auth_token="+auth_token;
      const windowFeatures = "menubar=no,location=no,resizable=no,scrollbars=yes,status=no,width=400,height=600";
      this.popupWorkout = window.open(url, 'popupWorkout', windowFeatures);
    },

  }
}
export default component;
</script>

<style lang="scss">
.msp-esporta-garmin {
  .buttons-container {
    background: var(--col-grigio-scuro);
  }
  .v-progress-linear {
    margin-bottom: 10px;
  }
  .v-alert__content {
      white-space: pre-line;
  }
}
</style>
