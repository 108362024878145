var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dettaglio-custom-edit"},[_c('editor',{attrs:{"init":{
    skin: 'oxide',
    height: 500,
    menubar: false,
    contextmenu: false,
    browser_spellcheck: true,
    plugins: 'link image hr',
    default_link_segmento: '_blank',
    toolbar: ' bold italic underline',
  }},on:{"update":function($event){return _vm.update()},"input":function($event){return _vm.debounceUpdate()}},model:{value:(_vm.dettaglio.note_attivita),callback:function ($$v) {_vm.$set(_vm.dettaglio, "note_attivita", $$v)},expression:"dettaglio.note_attivita"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }