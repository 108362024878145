<template>

  <div class="msp-videos">
    <div class="video-list active">
      <ul class="video-link"><li v-for="anteprima in sortedAnteprime" :key="anteprima.id"><a :href="anteprima.url"  segmento="_blank" rel="noopener noreferrer" class="anteprima-video">
        <span class="anteprima-video-img">
          <img :src="anteprima.thumbnail_url" alt="" />
        </span>
        <span class="anteprima-video-title" v-text="anteprima.title"></span>
      </a></li></ul>
    </div>
  </div>
</template>

<script>
import allenamentoCalcoli from "@src/js/vue/mixins/allenamentoCalcoli.js";
import env from "@mixins/environment.js"
import _api from "@mixins/api.js";



const component = {
  mixins: [
    allenamentoCalcoli,
    env,

  ],
  props: [
    "value",
    "single"
  ],
  data: function() {
    return {
      myValue: this.value,
      videos: [],
      anteprime: [],


    };
  },
  watch: {
    value: function (val)
    {
      this.myValue = val;
      this.updateVideos();

    }
  },

  mounted() {
    this.init();

  },
  computed: {
        sortedAnteprime: function () {
            return this.anteprime.sort((a,b)=>{
                return (a.id > b.id) ? 1 : -1;
            });
        },
  },
  methods: {
    init: function ()
    {
      this.updateVideos();

    },
    loadVideo: function (video) {

      let url = this.api_base_url+"/api/v1/video.php?url="+video;

      return new Promise((resolve,reject)=>{
        _api(url, "GET")
        .then((result)=>{
            resolve(result);
        })
        .catch((error)=>{
            reject(error);
          })
      });
    },

    updateVideos: function () {
      this.anteprime = [];
      if (!this.myValue) {
        this.videos = [];
        return false;
      }
      this.videos = this.myValue.trim().split("\n").filter(el=>el);

      if (this.videos && this.videos.length) {
        this.videos.forEach(
        ( video, i )=>{
            this.loadVideo(video)

            .then(
            (result)=>{
                let anteprima = {
                    id: i,
                    title: 'titolo',
                    thumbnail_url: '',
                    url: video,
                }
                if (result.title) {
                    anteprima.title = result.title;
                }
                if (result.thumbnail_url) {
                    anteprima.thumbnail_url = result.thumbnail_url;
                }
                this.anteprime.push(anteprima);

            }
            )
        }
        );
      }

    },
  }

}
export default component;
</script>

<style lang="scss">
.msp-videos {
  position: relative;
  .btn.btn-edit {
  position: absolute;
  top: 0;
  right: 0;
}
  .anteprima-video {
    display: flex;
    align-items: center;
  }
  .anteprima-video-img {
    display: block;
    max-width: 150px;
    img {
      display: block;
      width: 100%;
    }
  }
  .anteprima-video-title {
    display: flex;
    padding: 0.5em;
    white-space: normal;
  }
  .video-edit,
  .video-list {
    display: none;

    textarea{
      margin-bottom: 0.5em;
      line-height: 1.6;
      width: 100%;
    }
    .video-link{
      width: 100%;
      padding: 10px;
      margin-bottom: 0.5em;
      text-align: left;
      list-style: none;
      background-color: var(--col-grigio-chiarissimo);
      border-radius: 5px;

      a{
        color: var(--col-grigio-scuro);
        line-height: 1.6;
        &:hover{
          color: var(--col-msp);
          &:before{
            color: var(--col-msp);
          }
        }
      }
    }
  }
  .video-list.active {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  .video-edit.active{
    display: block;
    width: 400px;
  }
}

// per il modal dell'atleta con sola-lettura
.scheda--atleta .msp-videos .video-list .video-link {
    background-color: transparent;
}


</style>
