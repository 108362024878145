<template lang="html">
  <div class="allenamento-dettaglio">

    <div
    class="allenamento-palestra-dettaglio"
    v-for="(dettaglio, i_d) in allenamento.dettagli"
    :class="classDettaglio(i_d)"
    :key="i_d"
    >
    <div class="allenamento-palestra-descrizione">
        <h3>
            <span class="dettaglio-fase">
                {{dettaglio.des_attivita}}
            </span>
            <div class="dettaglio-totale">

                <msp-totale-dettaglio
                    :dettaglio = "dettaglio"
                    :zone-attive = "allenamento.zone_attive"
                    :sport_id = "allenamento.sport_id"
                >

                </msp-totale-dettaglio>
            </div>
        </h3>
      <v-btn icon @click="toggleDone(i_d)"><v-icon>{{iconDone(i_d)}}</v-icon></v-btn>
    </div>
    <template v-for="(serie, i_s)
    in dettaglio.serie">
    <div
    v-for="(andatura, i_a) in serie.andature"
    :key="i_s+'-'+i_a"
    class="allenamento-palestra-andatura"
    :class = "classImageExpanded(i_d,i_s,i_a)"
    >

    <div class="allenamento-palestra-col1">
      <p class="andatura-descrizione pre" v-text="andatura.descrizione"></p>
    </div>

    <div class="allenamento-palestra-col2">
      <div v-show="andatura.note"
      :class = "classNoteExpanded(i_d,i_s,i_a)"
      class="andatura-note pre" v-text="andatura.note"></div>
      <div @click="toggleExpanded(i_d,i_s,i_a)" v-if="andatura.imageId" class="andatura-immagine-container">
        <img v-if="andatura.images['300']" class="andatura-immagine" :src=" resources_base_url +'/'+andatura.images['300']" alt="" />
      </div>
    </div>


    <div class="allenamento-palestra-col3">
      <div class="andatura-buttons">
        <v-btn icon v-if="andatura.note" @click="toggleNote(i_d,i_s,i_a)">
          <v-icon>mdi-note-text</v-icon>
        </v-btn>

        <a v-if="andatura.url_youtube" :href="andatura.url_youtube" segmento="_blank" rel="noopener noreferrer" class="btn btn-primary"><i class="msp-icon-video"></i></a>
      </div>
    </div>

  </div>
</template>
</div>
</div>
</template>

<script>
import mspTotaleDettaglio from "./MspTotaleDettaglio.vue";

import allenamentoCalcoli from "@src/js/vue/mixins/allenamentoCalcoli.js";
import env from "@mixins/environment.js"



const component = {
  mixins: [
    allenamentoCalcoli,
    env
  ],
  components: {
    mspTotaleDettaglio,
  },

  props: ['allenamento'],
  data: function(){
    return {
      done: [],
      expanded: [],
      note: [],
    }
  } ,
  mounted: function () {
    this.onInit();
  },
  computed: {
    classScheda: function ()
    {
      return "";
    },

  },
  methods: {

    classDettaglio: function (index)
    {

      let classes = [" "];
      let d = this.done.find(el=>el.index===index);
      let done = (d) ? d.done : false;
      if (done)
      {
        classes.push("fatto");
      }

      return classes.join(" ");
    },
    classNoteExpanded: function (i_d=0,i_s=0,i_a=0)
    {
      let a = [i_d,i_s,i_a];
      let index = a.join("-");
      let classes = [" "];
      let ne = this.note.find(el=>el.index===index);
      let noteExpanded = (ne) ? ne.expanded : false;
      if (noteExpanded)
      {
        classes.push("note-expanded");
      }

      return classes.join(" ");
    },
    classImageExpanded: function (i_d=0,i_s=0,i_a=0)
    {
      let a = [i_d,i_s,i_a];
      let index = a.join("-");
      let classes = [" "];
      let e = this.expanded.find(el=>el.index===index);
      let expanded = (e) ? e.expanded : false;
      if (expanded)
      {
        classes.push("expanded");
      }

      return classes.join(" ");
    },
    toggleDone: function (index)
    {
      let d = this.done.find(el=>el.index===index);
      if (!d)
      {
        this.done.push({
          index: index,
          done: true,
        })
        return;
      }
      d.done = !d.done;

    },
    iconDone: function (index)
    {
      let d = this.done.find(el=>el.index===index);
      return (d&&d.done)?'mdi-checkbox-marked-circle-outline':'mdi-radiobox-blank';

    },
    toggleExpanded: function (i_d,i_s,i_a)
    {
      let a = [i_d,i_s,i_a];
      let index = a.join("-");
      let d = this.expanded.find(el=>el.index===index);
      if (!d)
      {
        this.expanded.push({
          index: index,
          expanded: true,
        })
        return;
      }
      d.expanded = !d.expanded;

    },
    toggleNote: function (i_d,i_s,i_a)
    {
      let a = [i_d,i_s,i_a];
      let index = a.join("-");
      let d = this.note.find(el=>el.index===index);
      if (!d)
      {
        this.note.push({
          index: index,
          expanded: true,
        })
        return;
      }
      d.expanded = !d.expanded;

    },


    formatRisultato(val) {
      return this.MSP().sec2hms(val);

    },


    youtubeLink: function (url_youtube)
    {
      let id = this.MSP().youtube_parser(url_youtube)

      if (id)
      {
        return "https://www.youtube-nocookie.com/embed/"+id;
      }
      else
      {
        return false
      }

    },

    playVideo: function (andatura,ev)
    {
      ev.segmento.src = andatura.url_youtube;
      ev.segmento.play();
    },


    onInit: function () {

    },


  }
}
export default component;
</script>

<style lang="scss">
.allenamento-palestra-dettaglio {
  --padding: 10px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin: 5px auto 20px 0;
  max-width: 600px;
  box-shadow: 0 0 3px rgba(0,0,0,0.3);
  position: relative;

  .allenamento-palestra-descrizione {
    display: flex;
    align-items: flex-start;
    background: var(--col-msp-chiaro);
    color: #000;
    margin: calc(-1 * var(--padding)) calc(-1 * var(--padding)) calc(1 * var(--padding)) calc(-1 * var(--padding));

    h3 {
      padding: calc(var(--padding)) calc(var(--padding)) 5px;
      // padding: calc(5px + var(--padding));
      font-weight: bold;
      flex:1;
      margin: 0;
    }

  }
  background: #fff;

  // &:nth-child(odd) {
  //   background: var(--col-grigio-chiarissimo);
  // }


  &.fatto {
    padding-top: 0;
    padding-bottom: 0;

    .allenamento-palestra-andatura {
      display: none;
    }
    .allenamento-palestra-descrizione {
      margin-bottom: 0;

      h3{
        margin:0;
        padding: 5px 5px 2px;
        font-size: 1.3em;
        overflow:hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }



}


.allenamento-palestra-andatura {
  display: flex;
  padding: 0px;
  border-top: 2px solid var(--col-msp);

  position: relative;
  margin-bottom: 5px;
  flex-wrap: wrap;
  --img-size: 100px;

  .msp-icon-video{
    font-size: 2em;
    line-height: 0;
  }

  .allenamento-palestra-col3 {
  }
  .allenamento-palestra-col2 {
    min-height: 0px;
    flex-basis: 100%;
  }



  .allenamento-palestra-col1 {
    display: flex;
    flex-direction: column;
    flex: 1;
    flex-basis: 80%;
    order: 1;
  }

  .allenamento-palestra-col2 {
    padding-right: 10px;
    display: flex;
    align-items: stretch;
    margin-bottom: 10px;
    flex-direction: column;
    order: 3;

  }
  .allenamento-palestra-col3 {
    // border-left: 1px solid var(--col-grigio-chiaro);
    padding-left: 10px;
    flex:0;
    order: 2;
  }
  .andatura-descrizione {
    font-size: 1.2em;
    font-weight: normal;
    border-left: 10px solid var(--col-msp);
    padding-left: 3px;
    padding-bottom: 0;
    margin-bottom: 8px;
  }
  .andatura-immagine-container {
    width: var(--img-size);
    height: var(--img-size);
    margin-right: 10px;
    background: var(--col-grigio-chiaro);

  }
  .andatura-immagine {
    display: block;
    width: 100%;
    height: 100%;
    object-fit:contain;
    border: 1px solid var(--col-grigio-chiaro);
  }
  .andatura-note {
    padding: 0 0 5px 0;
    font-style: italic;
    flex: 1;
    background: var(--col-ui-evidenza-tint);
    padding: 5px;
  }
  .andatura-note {
    display: none;
    &.note-expanded {
      display: flex;
    }
  }

  .andatura-buttons {
    display: flex;
    flex: 0;
    flex-direction: row;
    align-items: flex-start;
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
    }
  }



  &.expanded {
    .andatura-immagine-container {
      max-width: 300px;
      width: 100%;
      height: auto;
      min-height: 300px;
      margin-bottom: 10px;
    }
  }

}


</style>
