<template lang="html">
  <div
  class="msp-test-detail-detail mx-auto"
  >
  <v-app-bar app
  >
  <v-btn icon @click="dismissModal()">
    <v-icon>mdi-chevron-left</v-icon>
  </v-btn>

  <v-toolbar-title>
    <div class="test-titolo">
      <h2>{{formatDate(test.data)}}</h2>
    </div>
  </v-toolbar-title>


</v-app-bar>
<v-app>


  <v-container fluid>
    <div v-if="risultato">
      <v-btn block color="secondary" class="btn-save" @click="saveAndClose">Salva <span class="btn-risultato" v-html="formattedRisultato"></span></v-btn>
    </div>

    <v-tabs-items v-model="tab">
      <v-tab-item
      key="test"
      value="test"
      >

      <calcolo-test
      :passo="passo"
      :soglia="soglia"
      :fixed-data="test.data"
      :sport_id="sport_id"
      :test="test"
      :account="account"
      @on_calcola='setResultsCalcolo'>
    </calcolo-test >

  </v-tab-item>
  <v-tab-item
  key="zone"
  value="zone"
  >


  <zone :sport_id="sport_id" :um_id="test.um_id" :nome_zone="nomeZone"  :zone="zone" :um_descrizione="test.um_descrizione"></zone>

  <div class="msp-ui-panel">
    <div v-show="formatSoglia" class="msp-ui-value">
      <div class="msp-ui-panel-header">
        <h3 v-text="test.descrizione_soglia"></h3>
      </div>
      <p v-html="formatRisultatoByUmId(soglia.value, test.um_id, test.um_descrizione)"></p>

    </div>
  </div>
</v-tab-item>
</v-tabs-items>
<div v-if="risultato">
  <v-btn block color="secondary" class="btn-save" @click="saveAndClose">Salva <span class="btn-risultato" v-html="formattedRisultato"></span></v-btn>
</div>

</v-container>


<v-bottom-navigation
scroll-segmento="#app"
app>
<!-- -->
<v-tabs
v-model="tab"
centered
icons-and-text
dark
>

<v-tab
v-for="item in filteredTabs"
:key="item.id"
:href="'#'+item.id"
>
<span class="small">{{ item.text }}</span>
<v-icon>{{ item.icon }}</v-icon>
</v-tab>
</v-tabs>

</v-bottom-navigation>
</v-app>
</div>


</template>

<script>
import env from "@mixins/environment.js"
import _api from "@mixins/api.js";
import allenamentoCalcoli from "@src/js/vue/mixins/allenamentoCalcoli.js";
import testCalcolatore from "@mixins/test_calcolatore.js"
import calcoloTest from "@components/calcoloTest.vue";
import zone from '@src/js/vue/include/Zone.vue'

export default {
  components: {
    zone,

    calcoloTest,
  },
  mixins: [
    allenamentoCalcoli,
    testCalcolatore,
    env,
  ],
  props: [
    'id',
  ],
  data: function() {
    return {
      test: null,
      passo: new this.MSP().Passo(),
      soglia: new this.MSP().Passo(),
      tab: 'test',
      tests: [],
      tabs: [
        { id: 'test',
        text: 'test',
        icon: 'mdi-calendar-blank',
      },
      { id: 'zone',
      text: 'zone',
      icon: 'mdi-heart',
    },
  ],
  date: ''
};
},

watch: {
    id: function (nv, ov) {
        if (nv!==ov) {
            this.init();
        }
    }

},

mounted() {
  this.init();
},

computed: {
  formattedRisultato () {
    return this.formatRisultatoByUmId(this.soglia.value, this.test.um_id, this.test.um_descrizione);
  },
  sport_id () {
    return this.test && this.test.sport_id;
  },
  filteredTabs () {
    let hasZone = this.zone && this.zone.length;
    let clone = [...this.tabs];
    return (hasZone) ? clone : clone.splice(0,1);
  }
},
methods: {
  init: async function () {
      this.clear();
    let calendarioTest = await this.loadTest(this.id);
    let test = await this.loadTestDettaglio(calendarioTest.data.data.test_id);
    this.test = {
        ...test.data,
        data: calendarioTest.data.data.data,
    }
  },
  saveAndClose () {
    this.save()
    .then(
      async (result)=>{
      if (result.success)
      {

        let limits = {
          start: this.test.data.substr(0,10),
          end: this.test.data.substr(0,10),
        }

        await this.$store.dispatch("calendario/loadCalendar",limits);
        this.dismissModal();

      }
        this.dismissModal();
    })

  },

  loadTestDettaglio (id) {
    let url = this.api_base_url+"/api/v2/test.php?dettaglio&id="+id;

    return new Promise((resolve,reject)=>{
        _api(url, "GET")
            .then((result)=>{
          resolve(result);
            })
            .catch((error)=>{
          reject(error);
        });
    });

  },

  loadTest (id) {
    let url = this.api_base_url+"/api/v2/calendario.php?id="+id;

    return new Promise((resolve,reject)=>{
        _api(url, "GET")
      .then((result)=>{
          resolve(result);
      })
      .catch((error)=>{
          reject(error);
        });
    });

  },

  dismissModal () {
    this.$store.dispatch("calendario/setDialog", false);

  },

  submit: function ()
  {
    this.$emit("submit", this.test);
  },

  formatRisultatoByUmId(val, um_id, um_descrizione) {
    return this.MSP().formatRisultatoByUmId(val, um_id, um_descrizione)
  },

}

}
</script>

<style lang="scss">
.btn-save {
  margin-bottom: 5px;
}
.btn-risultato {
  display: inline-block;
  background-color: #fff;
  color: #000;
  padding: 0 0.1em;
  margin: 0 0.5em;
}
</style>
