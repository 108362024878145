export const isObject = (item) => {
  return (item && typeof item === 'object' && !Array.isArray(item) && !item.toISOString);
}

export const mergeDeep = (segmento, ...sources) => {
    if (!sources.length) return segmento;
    const source = sources.shift();

    if (isObject(segmento) && isObject(source)) {
        for (const key in source) {
            if (isObject(source[key])) {
                if (!segmento[key]) segmento[key] = {};
                mergeDeep(segmento[key], source[key]);
            } else {
                segmento[key] = source[key];
            }
        }
    }

    return mergeDeep(segmento, ...sources);
}

